export const SELECT_CLASSROOM = 'SELECT_CLASSROOM'
export const SELECT_STUDENT = 'SELECT_STUDENT'
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER'
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER'

export const FETCH_CLASSROOMS_REQUEST = 'FETCH_CLASSROOMS_REQUEST'
export const FETCH_CLASSROOMS_SUCCESS = 'FETCH_CLASSROOMS_SUCCESS'
export const FETCH_CLASSROOMS_FAILURE = 'FETCH_CLASSROOMS_FAILURE'

export const FETCH_STUDENTS_OF_CLASSROOM_REQUEST = 'FETCH_STUDENTS_OF_CLASSROOM_REQUEST'
export const FETCH_STUDENTS_OF_CLASSROOM_SUCCESS = 'FETCH_STUDENTS_OF_CLASSROOM_SUCCESS'
export const FETCH_STUDENTS_OF_CLASSROOM_FAILURE = 'FETCH_STUDENTS_OF_CLASSROOM_FAILURE'
