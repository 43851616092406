import {
  SELECT_CLASSROOM,
  SELECT_STUDENT,
  SET_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER,
  FETCH_CLASSROOMS_REQUEST,
  FETCH_CLASSROOMS_SUCCESS,
  FETCH_CLASSROOMS_FAILURE,
  FETCH_STUDENTS_OF_CLASSROOM_REQUEST,
  FETCH_STUDENTS_OF_CLASSROOM_SUCCESS,
  FETCH_STUDENTS_OF_CLASSROOM_FAILURE
} from '../action/actionTypes'

const groupInitialState = {
  classrooms: [],
  students: [],
  studentsReady: false,
  loading: false,
  error: null,
  selectedClassroom: null, //{id,name}
  selectedStudent: null,   //{id,name}
  searchFilter: ''
}

const groupReducer = (state = groupInitialState, action) => {
  switch (action.type) {
    case FETCH_CLASSROOMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_CLASSROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        classrooms: action.payload
      }
    case FETCH_CLASSROOMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SELECT_CLASSROOM:
      return {
        ...state,
        selectedClassroom: action.payload,
        selectedStudent: null,
        searchFilter: ''
      }
    case FETCH_STUDENTS_OF_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_STUDENTS_OF_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        studentsReady: true,
        students: action.payload
      }
    case FETCH_STUDENTS_OF_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SELECT_STUDENT:
      return {
        ...state,
        selectedStudent: action.payload
      }
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload
      }
    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: ''
      }
    default:
      return state
  }
}

export default groupReducer
