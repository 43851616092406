// App paths

export default {
    LOGIN: '/login',
    PASSWORD_RECOVERY: '/password-recovery',
    ERROR: '/error',
    HOME: '/home',
    CONFIG_BANNER: "/configuration/home-image",
    ADMIN_MODULE_CATALOG: '/administrative/moduleCatalog',
    INFOTIS_SCRIPT_LIST: '/qualification/scripts',
    INFOTIS_SCRIPT_ADD: '/qualification/script/add',
    INFOTIS_SCRIPT_EDIT: '/qualification/script/edit/:scriptId',
    INFOTIS_SCRIPT_VIEW: '/qualification/script/view/:scriptId',
    INFOTIS_MODULE_LIST: '/qualification/modules',
    INFOTIS_MODULE_ADD: '/qualification/module/add',
    INFOTIS_MODULE_EDIT: '/qualification/module/edit/:moduleId',
    INFOTIS_MODULE_ENDING: '/qualification/module/ending/:endingId',
    INFOTIS_MODULE_VIEW: '/qualification/module/view/:moduleId',
    USER_LOG_LIST: '/administrative/userLogs',
    USER_LIST: '/registries/admins',
    USER_ADD: '/registries/admin/create',
    USER_EDIT: '/registries/admin/:userEmail',
    USER_INVITE_CONFIRMARION: '/confirm-invite/:inviteCode',
    USER_RESET_PASSWORD: '/recover-password/:resetPasswordCode',
    PLAYER_LIST: '/registries/app-users',
    PLAYER_ADD: '/registries/app-user/create',
    PLAYER_EDIT: '/registries/app-user/:playerRef',
    PLAYER_VIEW: '/registries/app-user/:playerRef/view',
    WEAPON_LIST: '/registries/weapons',
    WEAPON_ADD: '/registries/weapon/add',
    WEAPON_EDIT: '/registries/weapon/edit/:weaponId',
    WEAPON_VIEW: '/registries/weapon/view/:weaponId',
    TRACK_LIST: '/qualification/tracks',
    TRACK_ADD: '/qualification/track/add',
    TRACK_EDIT: '/qualification/track/edit/:trackId',
    INFOTIS_SESSION_LIST: '/qualification/sessions',
    INFOTIS_SESSION_ADD: '/qualification/session/add',
    INFOTIS_SESSION_EDIT: '/qualification/session/edit/:infotisSessionId',
    INFOTIS_SESSION_REPORT: '/qualification/session/report/:infotisSessionId',
    INFOTIS_SESSION_SUMMARY_REPORT: '/qualification/session/summaryReport/:infotisSessionId',
    INFOTIS_SESSION_BIOTELEMETRY_REPORT:"/qualification/session/biotelemetryReport/:infotisSessionId",
    INFOTIS_SESSION_BIOTELEMETRY_DETAILS:"/qualification/session/biotelemetryReport/details/:playerId",
    ERROR_NOT_IMPLEMENTED: '/NotImplementedError',
    REPORT_INDIVIDUAL: '/report/individual',
    //new menu SIMULATOR REPORTS
    REPORT_SIMULATOR_SECTION_INDIVIDUAL: '/report/simulator/session-individual',
    REPORT_SIMULATOR_SECTION_FULL: '/report/simulator/group', // *** TEMP NAME / LOCATION - WAITING DEFINITION ---
    REPORT_SIMULATOR_CONSOLIDATED_INDIVIDUAL: '/report/simulator/consolidated-individual',
    REPORT_SIMULATOR_TIME_INDIVIDUAL: '/report/simulator/time-individual',
    REPORT_SIMULATOR_TIME_GENERAL: '/report/simulator/time-general',
    REPORT_SIMULATOR_CONSOLIDATED_GENERAL: '/report/simulator/session-geral',
    //NEW
    REPORT_REACT_INDIVIDUAL: '/report/react-individual',
    REPORT_HTML2PDF: '/report/html2pdf',
    REPORT_PDFMAKE: '/report/pdfmake',
    REPORT_GROUP: '/report/group',

    REPORT_PLAYERS: '/report/overview',
    REPORT_OVERVIEW: '/report/students',
    APP_LIST: '/registries/apps'
    
}
