const reactIndividualReportInitialState = {
    loading: false,
    companyPlayers: null
}

const reactIndividualReport = (state = reactIndividualReportInitialState, action) => {
    switch (action.type) {
        case 'BEGIN_GET_REPORT_DATA':
            return {
                ...state,
                loading: true
            }
        case 'ERROR_GET_REPORT_DATA':
            return { 
                ...state 
            }
        case 'SUCCES_GET_REPORT_DATA':
            return {
                ...state,
                companyPlayers: action.companyPlayers
            }
        case 'END_GET_REPORT_DATA':
            return {
                ...state,
                loading: false
            }
        default:
            return { 
                ...state 
            }
    }
}
export default reactIndividualReport
