const initialState = {
    sessionsList: [],
    loading: false,
    error: null
}

const sessionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SESSIONS_REQUEST':
            return { ...state, loading: true, error: null }
        case 'GET_ALL_SESSIONS_SUCCESS':
            return { ...state, loading: false, sessionsList: action.payload }
        case 'GET_ALL_SESSIONS_FAILURE':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export default sessionsReducer
