const individualReportInitialState = {
  loading: false,
  qsEmbedUrl: null,
  lastEmbedUrlReceivedAt: null,
  companyPlayers: null,
  currentPlayer: null
}

const individualReport = (state = individualReportInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_PLAYERS_REPORT':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_PLAYERS_REPORT':
      return {
        ...state,
        loading: false
      }
    case 'GET_PLAYERS_REPORT':
      return {
        ...state,
        qsEmbedUrl: action.qsEmbedUrl,
        companyPlayers: action.companyPlayers,
        lastEmbedUrlReceivedAt: new Date(),
        loading: false
       }
    case 'BEGIN_LOADING_PLAYER_ACTIVITIES_REPORT':
    return {
      ...state,
      loading: true
    }
    case 'FINISH_LOADING_PLAYER_ACTIVITIES_REPORT':
    return {
      ...state,
      loading: false
    }
    case 'GET_PLAYER_ACTIVITIES_REPORT':
      return {
        ...state,
        currentPlayer: action.currentPlayer,
        loading: false
      }

    default:
      return { ...state }
  }
}
export default individualReport
