const initialState = {
    reportData: null,
    loading: false,
    error: null
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REPORT_REQUEST':
            return { ...state, loading: true, error: null }
        case 'GET_REPORT_SUCCESS':
            return { ...state, loading: false, reportData: action.payload }
        case 'GET_REPORT_FAILURE':
            console.log('RESET_REPORT_DATA')
            return { ...state, loading: false, error: action.payload }
        case 'RESET_REPORT_DATA':
            return { ...state, loading: false, reportData: null }
        default:
            return state
    }
}

export default reportReducer
