// ** Initial State
const initialState = {
  clientVersion: process.env.REACT_APP_VERSION,
  serverVersion: null,
  userCompanyName: null,
  userCompanyId: null,
  userCompanyCandidates: [],
  loading: true,
  companySimulatorAppId: null // used on Vertical Nav Menu to show/hide simulator menu item
}

const general = (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_SERVER_VERSION':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_SERVER_VERSION':
      return {
        ...state,
        loading: false
      }
    case 'BEGIN_LOADING_CURRENT_USER_INFO':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_CURRENT_USER_INFO':
      return {
        ...state,
        loading: false
      }
    case 'BEGIN_CHANGING_CURRENT_COMPANY':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_CHANGING_CURRENT_COMPANY':
      return {
        ...state,
        loading: false
      }
    case 'GET_SERVER_VERSION':
      return {
        ...state,
        serverVersion: action.serverVersion,
        loading: false
      }
    case 'GET_CURRENT_USER_INFO':
      return {
        ...state,
        userCompanyName: action.userCompanyName,
        userCompanyId: action.userCompanyId,
        userCompanyCandidates: action.userCompanyCandidates,
        companySimulatorAppId: action.companySimulatorAppId,
        loading: false
      }
    case 'CHANGE_CURRENT_COMPANY':
      return {
        ...state,
        userCompanyName: action.userCompanyName,
        userCompanyId: action.userCompanyId,
        loading: false
      }
    default:
      return { ...state }
  }
}
export default general
