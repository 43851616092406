// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import general from './general'
import users from '@src/views/registries/user/store/reducer'
import players from '@src/views/registries/player/store/reducer'
import weapons from '@src/views/registries/weapon/store/reducer'
import infotisScripts from '@src/views/qualification/infotisScript/store/reducer'
import infotisModules from '@src/views/qualification/infotisModule/store/reducer'
import tracks from '@src/views/qualification/track/store/reducer'
import apps from '@src/views/registries/app/store/reducer'
import infotisSessions from "@src/views/qualification/infotisSession/store/reducer"
import userLogs from "@src/views/administrative/userLogs/store/reducer"
import playerReport from "@src/views/reports/players/store/reducer"
import overviewReport from "@src/views/reports/overview/store/reducer"
import individualReport from "@src/views/reports/individual/store/reducer"
import reactIndividualReport from "@src/views/reports/react-individual/store/reducer"
import TodoReducer from "@src/views/home/store/reducer"
import ConfigBanner from "@src/views/configurations/store/reducer"
import groupReducer from "@src/views/reports/simulator/group/store/reducer"
import sessionIndividual from "@src/views/reports/simulator/session-individual/store/reducer"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  general,
  users,
  players,
  weapons,
  infotisScripts,
  infotisModules,
  tracks,
  apps,
  infotisSessions,
  userLogs,
  playerReport,
  overviewReport,
  individualReport,
  reactIndividualReport,
  TodoReducer,
  ConfigBanner,
  group: groupReducer, //make alias shorter following convenction
  sessionIndividual
})

export default rootReducer
