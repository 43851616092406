import { combineReducers } from 'redux'
import playersReducer from './playersReducer'
import sessionsReducer from './sessionsReducer'
import reportReducer from './reportReducer'

const sessionIndividual = combineReducers({
    players: playersReducer,
    sessions: sessionsReducer,
    report: reportReducer
})

export default sessionIndividual
